








import { Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';

import ButtonLike, { ButtonType } from '@/mixins/ButtonLike';

@Component({})
export default class Label extends mixins(ButtonLike) {
  baseClass = 'label';

  @Prop({ default: 'outline' }) type: ButtonType;
}
