


































































import { Vue, Component, Watch, Prop, Emit, Ref } from 'vue-property-decorator';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Button from '@/components/ui/button';
import DocumentMeta from './DocumentMeta.vue';
import ButtonGroup from '@/components/ui/button-group';
import Label from '@/components/ui/label';
import Icon from '@/components/ui/icon/Icon.vue';
import levenshteinFilter from '@/utils/levenshteinFilter';
import FindOrCreate from '@/components/context-menu/repeatable/FindOrCreate.vue';
import SubRoleSelect from '@/components/user/SubRoleSelect.vue';
import RolesModule from '@/store/modules/roles/RolesModule';
import Submenu from '@/components/ui/submenu/Submenu.vue';
import InviteSubmenu from '@/components/ui/submenu/InviteSubmenu.vue';
import { Specialisations, SubRole } from '@/typings/domain';

@Component({
  name: 'DocumentMetaAudience',
  components: {
    DocumentMeta,
    InviteSubmenu,
    ContextMenuHeader,
    Button,
    FindOrCreate,
    ButtonGroup,
    Label,
    Icon,
    ContextMenuSpawner,
    SubRoleSelect,
    Submenu,
  },
})
export default class DocumentMetaAudience extends Vue {
  public search = '';
  @Prop() title: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop() mode: string;
  @Prop( { default: false }) hideAllSpecs: boolean;
  @Prop( { default: false }) hideForAll: boolean;
  @Prop({ default: null }) value: Array<Specialisations>;
  @Ref('ContextMenu') readonly ContextMenu!: ContextMenuSpawner;

  public specialisationValue = '';
  public showAddItem = false;
  public selected: Array<number> = [];
  public newSpecActive = false;
  public submenuStyles: object = { opacity: 0, transition: 'unset' };
  public forAllSelected = false;
  public rolesList: ({id: number, name: string, compiled?: boolean, items?: number[]} | SubRole)[] = [];
  public ForAll = {
    id: -999,
    name: this.$tc('Documents.Document.Meta.ForAll'),
  };

  mounted() {
    this.setRoles();

    try {
      if (this.rolesList.length >= this.specs.length )
      {
        this.selected = [this.ForAll.id];
        this.rolesList = [this.ForAll];
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  // @Watch('rolesList', { deep: true, immediate: true })
  onRolesListChange(newVal: ({ id: number, name: string, compiled?: boolean, items?: number[] } | SubRole)[])
  {
    try {
      if (this.selected.length >= this.specs.length)
      {
        this.selected = [this.ForAll.id];
        this.rolesList = [this.ForAll];
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  get actualRoles() {
    return RolesModule.actualRoles
  }
  get allRoles() {
    return RolesModule.roles;
  }
  get filtered() {
    return levenshteinFilter(this.allRoles, this.search);
  }
  get specs()
  {
    return RolesModule.specialisations
  }

  setRoles()
  {
    if(this.$route.path.indexOf('/team/user/') >= 0)
    {
      this.selected = this.value.map((el) => el.id);
      const filteredRoles = RolesModule.specialisations.filter(role => {
        return this.selected.includes(role.id);
      });
      this.rolesList = filteredRoles;
    }
    if (RolesModule.selectedRoles.length >= 1) {
      this.selected = RolesModule.activeRoles;
      const filteredRoles = RolesModule.specialisations.filter(role => {
        return this.selected.includes(role.id);
      });
      this.rolesList = filteredRoles;
      const rolesSpecsLength = RolesModule.roles.map(el => {return {role_id: el.id, name: `${el.name}: ${this.$tc('Documents.Document.Meta.All')}`, specLegth: el.specialisations.length, items: el.specialisations.map(i => i.id)}});
      if(this.rolesList?.[0]?.id !== -999) {
        const uniqueIDs = [...new Set((this.rolesList as SubRole[]).map(el => el.role_id))];         
        uniqueIDs.map(el => {
          let name;
          let items;
          if ((this.rolesList as SubRole[]).reduce((acc, cur) => cur.role_id === el ? ++acc : acc, 0) === (rolesSpecsLength.find(rls => { name = rls.name; items = rls.items; return rls.role_id === el; }).specLegth) && (rolesSpecsLength.find(rls => rls.role_id === el).specLegth) > 1) {
            this.rolesList = (this.rolesList as SubRole[]).filter(rl => rl.role_id !== el);
            this.rolesList.push({ id: el, name: name, compiled: true, items: items })
          }
        });
      }
      return filteredRoles;
    } else {
      if(this.mode === 'edit' && this.selected.length === 0) {
        this.selected = [this.ForAll.id];
        this.rolesList = [this.ForAll];
      }
      return RolesModule.specialisations.filter(role => {
        return this.selected.includes(role.id);
      });
    }
  }

  createSpecialisation() {
    RolesModule.createRole({ name: this.specialisationValue, isAdmin: false });
    this.newSpecActive = false;
  }
  resetSearch() {
    this.newSpecActive = false;
    this.search = '';
    this.specialisationValue = '';
  }
  remove(id: number, compiled?: boolean, items?: number[]) {
    if(!compiled) {
      const index = this.selected.indexOf(id);
      this.selected.splice(index, 1);
      this.rolesList = this.rolesList.filter(el => el.id !== id);
    } else {      
      this.selected = this.selected.filter(el => !items.includes(el));
      this.rolesList = this.rolesList.filter(el => el.id !== id);
    }
  }
  roleSelect(id: number) {
    if (this.selected.includes(-999) && id !== -999) {
      this.selected.pop();
      this.rolesList.pop();
    }
    const item = this.specs.find(el => el.id === id);
    const index = this.selected.indexOf(id);
    if (index === -1) this.selected.push(id);
    if (index === -1) this.rolesList.push(item);
  }

  isSelectedForAll(isSelected: boolean): void { 
    if(isSelected) {
      const ref = this.$refs.ContextMenu as ContextMenuSpawner;
      ref?.close();
      this.selected = [this.ForAll.id];
      this.rolesList = [this.ForAll]; 
    }
  }

  selectAllRoleSpecs({ids, roleName, list}: {ids: Array<number>, roleName: string, list: Array<SubRole>}) {
    if (this.selected.includes(-999) && this.selected.length === 1) {
      this.selected.pop();
      this.rolesList.pop();
      const ref = this.$refs.ContextMenu as ContextMenuSpawner;
      ref?.close();
    }
    const newID = list[0].role_id;    
    this.rolesList = this.rolesList.filter((el) => !ids.includes(el.id));

    return (
      this.rolesList.push({ id: newID, name: `${roleName}: ${this.$tc('Documents.Document.Meta.All')}`, compiled: true, items: ids }),
      (this.selected = [...new Set(this.selected.concat(ids))])
    );

  }

  @Emit('specialisationCreated')
  specialisationCreated()
  {
    return true
  }

  @Watch('actualRoles')
  onActualRolesChanged()
  {
    this.setRoles();
  }

  @Emit('paginateRoles')
  paginateRoles(page: number)
  {
    return page;
  }

  @Watch('selected')
  @Emit('change')
  onChange() {    
    const ref = this.$refs.ContextMenu as ContextMenuSpawner;
    ref?.close();    
    return this.selected;
  }
}
